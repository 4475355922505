import styled from '@emotion/styled'
import { graphql, StaticQuery } from 'gatsby'
import Link from 'gatsby-link'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button, SieInfo, SieUser } from '../../atoms'
import { isPostEvent } from './../../../config'
import { mq } from './../../../utility/breakpoint'
export const TopMenu = ({
  language,
  hasParticipation = false,
}: {
  language: string
  hasParticipation?: boolean
}) => {
  const { t } = useTranslation(`menuItems${isPostEvent() ? 'PostEvent' : ''}`)

  const getIcon = icon => {
    switch (icon) {
      case 'SieUser':
        return <SieUser />
      case 'SieInfo':
        return <SieInfo />
      default:
        return
    }
  }

  return (
    <StaticQuery
      query={graphql`
        query TopMenu {
          site {
            siteMetadata {
              topMenu {
                active
                icon
                name
                link
                hidePostEvent
              }
            }
          }
        }
      `}
      render={data => {
        const navData = [
          ...data.site.siteMetadata.topMenu.filter(
            ({ active }: { active: boolean }) => active
          ),
        ].filter(({ hidePostEvent }) =>
          isPostEvent && hidePostEvent && isPostEvent() && !hasParticipation
            ? false
            : true
        )

        return (
          <HeaderMenuContainer>
            {navData.map(({ name, icon, link, home, subitems }, index) => {
              return (
                <TopMenuItem
                  key={index}
                  to={`/${language || 'en'}${link}`}
                  activeClassName="active"
                  partiallyActive={link !== '/'}
                >
                  <span> {t(`${name}`)}</span>
                  {getIcon(icon)}
                </TopMenuItem>
              )
            })}
          </HeaderMenuContainer>
        )
      }}
    />
  )
}

const HeaderMenuContainer = styled.div({
  display: 'inline-flex',
  alignItems: 'flex-start',
  paddingTop: '1px',
})

const TopMenuItem = styled(Link)(({ theme: { fonts, colors } }) => ({
  gridColumn: '30/33',
  svg: {
    height: '20px',
  },
  fontFamily: fonts.fontFamilyBlack,
  marginTop: '1px',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
  display: 'inline-flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  textDecoration: 'none',
  color: colors.menuItem,
  letterSpacing: '-.5px',
  transition: 'color 0.25s',
  marginRight: '10px',

  span: {
    display: 'none',
  },
  '&:hover': {
    borderColor: '#0cc',
  },
  '&.active': {
    color: '#0cc',
    borderColor: '#0cc',
  },
  [mq[3]]: {
    span: {
      display: 'block',
    },
    marginRight: '30px',
    borderBottom: '2px solid transparent',
    height: '23px',
    svg: {
      marginLeft: '10px',
      height: '1rem',
    },
  },
}))

/*
const TopMenuItem = styled(Button)`
  white-space: nowrap;
  padding: 0;
  margin-right:30px;
  a{
    color:inherit;
    text-decoration:inherit;
  }
`
 */
