import styled from '@emotion/styled'
import { useOnClickOutside } from 'cuenect-web-core'
import { GatsbyLinkProps } from 'gatsby'
import Link from 'gatsby-link'

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Text } from '..'
import { mq } from '../../../utility'
import { SieClose, SieHome } from '../icons'

interface ISubItem {
  name: string
  link: string
  active: boolean
}

export interface MenuItemProps<T> extends GatsbyLinkProps<T> {
  home?: boolean
  language?: string
  subitems?: ISubItem[]
  onSubmenu?: Function
}

export function MenuItem<T>({ children, ref, ...rest }: MenuItemProps<T>) {
  const { to, home, language, subitems } = rest
  const { t } = useTranslation('menuItems')

  const sublinksActive = subitems
    ? subitems.filter(({ active }: { active: boolean }) => active)
    : null

  const [subMenuVisible, setSubMenuVisible] = useState(false)

  const MenuOverlayRef = React.useRef<HTMLDivElement>(null)
  useOnClickOutside(MenuOverlayRef, () => setSubMenuVisible(false))

  return (
    <>
      <StyledListItem>
        <Link
          to={`/${language || 'en'}${to}`}
          activeClassName="active"
          onClick={e => {
            if (sublinksActive && sublinksActive.length > 0) {
              // Need a link for active state
              e.preventDefault()
              setSubMenuVisible(!subMenuVisible)
            }
          }}
        >
          <span>{home ? <SieHome /> : children}</span>
        </Link>

        {sublinksActive && subMenuVisible && (
          <MenuOverlay ref={MenuOverlayRef}>
            <MenuTextBlock>
              <BlockLeft>
                <Text size={20} black>
                  {children}
                </Text>
              </BlockLeft>
              <BlockRight>
                <SubMenuContainer align="right">
                  <CloseButtonDiv onClick={() => setSubMenuVisible(false)}>
                    <SieClose />
                  </CloseButtonDiv>
                </SubMenuContainer>
                <SubMenuContainer align="left">
                  <ItemList>
                    {sublinksActive.map(item => (
                      <Item>
                        <Link
                          to={`/${language || 'en'}${item.link}/`}
                          partiallyActive={to !== '/'}
                          onClick={() => setSubMenuVisible(false)}
                        >
                          <span> {t(`${item.name}`)}</span>
                        </Link>
                      </Item>
                    ))}
                  </ItemList>
                </SubMenuContainer>
              </BlockRight>
            </MenuTextBlock>
          </MenuOverlay>
        )}
      </StyledListItem>
    </>
  )
}

const Item = styled.li(({ theme: { fonts, colors } }) => ({
  color: colors.link,
  fontFamily: fonts.fontFamily,
  fontSize: fonts.text.sizes[18],
  listStyle: 'none',
  padding: '0.8rem 0',
}))

const ItemList = styled.ul({
  paddingTop: '0.2rem',
})

const CloseButtonDiv = styled.div({
  display: 'inline-flex',
  cursor: 'pointer',
})

interface ISubMenuContainer {
  align?: 'left' | 'right'
}

const SubMenuContainer = styled.div<ISubMenuContainer>(({ align }) => ({
  textAlign: align ? align : 'left',
}))

const BlockLeft = styled.div({
  display: 'table-cell',
  verticalAlign: 'top',
  paddingTop: '2rem',
  width: '40%',
})

const BlockRight = styled.div({
  display: 'table-cell',
  verticalAlign: 'top',
  width: '60%',
})

const MenuTextBlock = styled.div({
  display: 'table',
  marginTop: '2rem',
  gridColumn: '1/16',
  [mq[3]]: {
    gridColumn: '3/35',
  },
})

const MenuOverlay = styled.div(({ theme: { grid, colors } }) => ({
  position: 'absolute',
  right: 0,
  left: 0,
  background: colors.bgSubMenu,
  display: 'grid',
  gridTemplateColumns: `repeat(${grid.columns},1fr)`,
  minHeight: '320px',
}))

const StyledListItem = styled.li(({ theme: { colors, fonts } }) => ({
  fontSize: '1.125rem',
  height: '100%',
  fontFamily: fonts.fontFamilyBlack,
  fontWeight: 400,
  color: colors.menuItem,
  marginRight: '28px',
  position: 'static',
  whiteSpace: 'nowrap',
  display: 'inline-block',
  '& a': {
    height: '100%',
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    color: colors.menuItem,
    letterSpacing: '-.5px',
    transition: 'color 0.25s',
    borderBottom: '2px solid transparent',
    '&:hover': {
      borderColor: '#0cc',
    },
    '&.active': {
      color: colors.menuItem,
      borderColor: '#0cc',
    },
    svg: {
      height: '1rem',
    },
  },
}))
