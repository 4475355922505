import styled from '@emotion/styled'
import { useFeatureFlags } from 'cuenect-web-core'
import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '../../atoms/menuItem'
import {
  isPostEvent,
  isPostEventAppointments,
  isPostEventFirst,
} from './../../../config'
export const HeaderMenu = ({
  language,
  hasParticipation,
}: {
  language: string
  hasParticipation: boolean | undefined
}) => {
  const { t } = useTranslation(
    `menuItems${
      isPostEventFirst() ? (isPostEvent() ? 'PostEvent' : 'PostEventFirst') : ''
    }`
  )
  const { phase2 } = useFeatureFlags()

  return (
    <StaticQuery
      query={graphql`
        query MainMenu {
          site {
            siteMetadata {
              mainMenu {
                active
                name
                home
                link
                hidePostEvent
                needParticipation
              }
            }
          }
        }
      `}
      render={data => {
        const navData = [
          ...data.site.siteMetadata.mainMenu.filter(
            ({ active }: { active: boolean }) => active
          ),
        ]
          .filter(({ hidePostEvent }) =>
            hidePostEvent && isPostEvent() ? false : true
          )
          .filter(({ needParticipation }) =>
            needParticipation
              ? !isPostEventAppointments()
                ? true
                : hasParticipation
              : true
          )

        return (
          <HeaderMenuContainer>
            {navData.map(
              ({ name, link, home, subitems, hidePostEvent }, index) => {
                return (
                  <MenuItem
                    to={link}
                    key={index}
                    home={home}
                    language={language}
                  >
                    {t(`${name}`)}
                  </MenuItem>
                )
              }
            )}
          </HeaderMenuContainer>
        )
      }}
    />
  )
}

const HeaderMenuContainer = styled.ul({
  display: 'inline-flex',
  listStyleType: 'none',
  padding: 0,
  margin: 0,
  height: '100%',
  pointerEvents: 'all',
})
